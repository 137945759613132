import { RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import { IconButton, Text, useTheme, View } from 'native-base';
import React from 'react';
import { FlatList, Image, TouchableOpacity } from 'react-native';
import { useQuery } from 'react-query';
import CustomAppbar from '../../../components/common/custom-appbar.component';
import LoadingSpinner from '../../../components/common/loading-spinner.component';
import { StackNavigatorParams } from '../../../components/common/navigator-stack.component';
import Spacer from '../../../components/common/spacer.component';
import { getArticles } from '../../../services/repositories/article';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';

type ArticleCategoriesScreenProps = {
  navigation: StackNavigationProp<StackNavigatorParams, 'ArticleCategories'>;
  route: RouteProp<StackNavigatorParams, 'ArticleCategories'>;
};

const ArticleCategoriesScreen = ({
  navigation,
  route,
}: ArticleCategoriesScreenProps) => {
  const { colors } = useTheme();
  const { category } = route.params;
  const {
    data: articles,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(['articles', { categoryId: category.id }], getArticles);

  return (
    <React.Fragment>
      <CustomAppbar
        leftAccessory={
          <IconButton
            onPress={() => {
              navigation.goBack();
            }}
            // color={colors.primary}
            icon={
              <MaterialCommunityIcons
                name="arrow-left"
                colors={colors.primary[500]}
                size={24}
              />
            }
          />
        }
        title={category.name}
      />
      {isLoading ? (
        <LoadingSpinner isFullscreen />
      ) : (
        <FlatList
          ListEmptyComponent={
            <View style={{ flex: 1 }}>
              <Text>Belum ada artikel</Text>
            </View>
          }
          contentContainerStyle={{ padding: 16 }}
          data={articles}
          ItemSeparatorComponent={() => <Spacer size="sm" />}
          keyExtractor={(item) => `${item.id}`}
          renderItem={({ item: article }) => {
            return <ArticleCard article={article} navigation={navigation} />;
          }}
        />
      )}
    </React.Fragment>
  );
};

const ArticleCard = ({ article, navigation }: any) => {
  const { colors } = useTheme();

  return (
    <TouchableOpacity
      activeOpacity={0.75}
      onPress={() => {
        navigation.navigate('ArticleDetails', { id: article.id });
      }}
    >
      <View
        bg="white"
        style={{
          borderRadius: 16,
          marginBottom: 16,
        }}
      >
        <Image
          style={{
            width: '100%',
            height: 180,
            overflow: 'hidden',
            borderRadius: 16,
          }}
          source={{
            uri: article.thumbnailUrl,
          }}
        />
        <View>
          <View
            style={{
              left: 0,
              bottom: 0,
              padding: 12,
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 20,
              }}
            >
              {article.title}
            </Text>
            <Text fontSize="sm" style={{ marginTop: 3 }}>
              oleh: {article.author}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

{
  /* <TouchableOpacity
                activeOpacity={0.75}
                onPress={() => {
                  navigation.navigate("ArticleDetails", { id: article.id });
                }}
              >
                <Surface
                  style={{
                    borderRadius: 16,
                    marginBottom: 16
                  }}
                >
                  <ImageBackground
                    style={{
                      width: "100%",
                      height: 180,
                      overflow: "hidden",
                      borderRadius: 16
                    }}
                    source={{
                      uri: article.thumbnailUrl
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "rgba(0,0,0,0.5)",
                        flex: 1
                      }}
                    >
                      <View
                        style={{
                          position: "absolute",
                          left: 0,
                          bottom: 0,
                          padding: 12
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: "bold",
                            fontSize: 20,
                            color: colors.background
                          }}
                        >
                          {article.title}
                        </Text>
                        <Caption style={{ color: colors.background, marginTop: 3 }}>
                          oleh: {article.author}
                        </Caption>
                      </View>
                    </View>
                  </ImageBackground>
                </Surface>
              </TouchableOpacity> */
}

export default ArticleCategoriesScreen;
