export const customColors = {
  primary: {
    50: '#E3F5F8',
    100: '#CAECF2',
    200: '#91D8E3',
    300: '#5DC5D5',
    400: '#30A8BB',
    500: '#227785',
    600: '#1B5F6A',
    700: '#154951',
    800: '#0D3035',
    900: '#071A1C',
  },
};
