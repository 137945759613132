// import { AuthContext } from './authContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { JWT_TOKEN_KEY } from '../../utils/constants';
import { UserProfile } from '../../utils/types';
import { getProfile } from '../repositories/user';

interface IContextProps {
  state: IState;
  setToken: any;
  deleteToken: any;
  authLoaded: boolean;
  // checkUser: () => Promise<void>;
}

interface IState {
  token: string | null;
  isLoggedIn: boolean | null;
  user: UserProfile | null;
}

interface IActions {
  type: string;
  payload?: Record<string, any>;
}

export const AuthContext = createContext<IContextProps | undefined>(undefined);

const initialState = {
  token: null,
  isLoggedIn: false,
  user: null,
};

type ThunkAction<T> = (dispatch: Dispatch<T>) => T;
type AsyncDispatch<T> = Dispatch<T | ThunkAction<T>>;

function wrapAsync<T>(dispatch: Dispatch<T>): AsyncDispatch<T> {
  return function (action: T | ThunkAction<T>) {
    if (action instanceof Function) {
      return action(dispatch);
    }
    return dispatch(action);
  };
}

const reducer = (state: any, action: any) => {
  console.log('Reducer', action.type);

  switch (action.type) {
    case 'DeleteToken':
      return { token: null, isLoggedIn: false };
    case 'SetToken':
      return {
        token: action.payload.token,
        isLoggedIn: action.payload.isLoggedIn,
      };
    case 'SetUser':
      return {
        ...state,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

export const AuthContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch]);
  const [authLoaded, setAuthLoaded] = useState(false);
  const queryClient = useQueryClient();
  useQuery('profile', getProfile, {
    enabled: !!state.isLoggedIn,
    onSuccess: (result) => {
      if (!result) return;
      dispatch({
        type: 'SetUser',
        payload: {
          user: result,
        },
      });
    },
  });

  const checkLoginStatus = async () => {
    setAuthLoaded(false);

    const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

    if (token) {
      dispatch({
        type: 'SetToken',
        payload: {
          token,
          isLoggedIn: true,
        },
      });
    } else {
      dispatch({
        type: 'SetToken',
        payload: {
          token: null,
          isLoggedIn: false,
        },
      });
    }

    setAuthLoaded(true);
  };

  const deleteToken = asyncDispatch((dispatch: Dispatch<IActions>) => {
    return async () => {
      await AsyncStorage.removeItem(JWT_TOKEN_KEY);

      queryClient.invalidateQueries();

      dispatch({ type: 'DeleteToken' });
      dispatch({
        type: 'SetUser',
        payload: {
          user: null,
        },
      });
    };
  });

  const setToken = asyncDispatch((dispatch: Dispatch<IActions>) => {
    return async (token: string) => {
      await AsyncStorage.setItem(JWT_TOKEN_KEY, token);

      dispatch({
        type: 'SetToken',
        payload: {
          token,
          isLoggedIn: true,
        },
      });
    };
  });

  useEffect(() => {
    checkLoginStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ state, setToken, deleteToken, authLoaded }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export function useAuth(): IContextProps {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }
  return context;
}
