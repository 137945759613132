import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../utils/api';
import { JWT_TOKEN_KEY } from '../../utils/constants';

export const getProfile = async () => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  console.log('Fetch profile');

  try {
    const response = await api.get<UserProfile>('/users/me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export type UserProfileDashboard = {
  totalOrders: number;
  monthlyAverageQuantity: number;
  totalQuantity: number;
  productsPerCategory: {
    id: number;
    name: string;
    amount: number;
  }[];
};

// totalOrders: orders.length,
//       monthlyAverageQuantity: productsMonthlyAverage,
//       totalQuantity: productsTotal,
//       productsPerCategory,

export const getProfileDashboard = async () => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  try {
    const response = await api.get<UserProfileDashboard>(
      '/users/me/dashboard',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log(response.data);

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export type UserProfile = {
  id: string;
  name: string;
  email: string;
  phone: string;
  balance: number;
  avatarUrl?: string;
};

export type UpdateProfileBody = {
  name: string;
  email: string;
  phone: string;
};

export const updateProfile = async (formData: UpdateProfileBody) => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  return api.patch('users/me/update', formData, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const uploadAvatarImage = async (imageUri: string) => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  const fileExt = imageUri.split('.');
  const fileName = imageUri.split('/');

  const formData: any = new FormData();

  formData.append('image', {
    uri: imageUri,
    name: fileName[fileName.length - 1],
    type: `image/${fileExt[fileExt.length - 1]}`,
  });

  console.log(formData);

  return api.patch('/users/me/avatar', formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      enctype: 'multipart/form-data',
    },
  });
};

// export class WithdrawBalanceDto {
//   @ApiProperty()
//   @IsNotEmpty()
//   @IsNumber()
//   amount: number;

//   @ApiProperty()
//   @IsNotEmpty()
//   @IsString()
//   accountName: string;

//   @ApiProperty()
//   @IsNotEmpty()
//   @IsString()
//   accountBankName: string;

//   @ApiProperty()
//   @IsNotEmpty()
//   @IsString()
//   accountNumber: string;
// }

export type WithdrawBalanceBody = {
  amount: number;
  accountName: string;
  accountBankName: string;
  accountNumber: string;
};

export const withdrawBalance = async (formData: WithdrawBalanceBody) => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  return api.post('withdrawal-requests', formData, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

// export const updateProfile = async (name: string) => {
//   const { data, error } = await supabase.from<UserProfile>("user").update({
//     name
//   });

//   if (error) {
//     console.log(error);
//     throw new Error(error.message);
//   }

//   return data;
// };

// export const uploadAvatar = async (file: string, filename: string) => {
//   const { data: objectKey, error: avatarError } = await supabase.storage
//     .from("avatars")
//     .upload(`user/${filename}`, file, {
//       cacheControl: "3600",
//       upsert: false
//     });

//   if (avatarError) {
//     throw new Error(avatarError.message);
//   }

//   const { data, error } = await supabase.storage.from("avatars").getPublicUrl(objectKey!.Key);

//   if (error) {
//     throw new Error(error.message);
//   }

//   const { data: updatedProfile, error: errorProfile } = await supabase
//     .from<UserProfile>("user")
//     .update({
//       avatar_url: data?.publicURL
//     });

//   if (errorProfile) {
//     throw new Error(errorProfile.message);
//   }

//   return updatedProfile;
// };
