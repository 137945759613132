import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { IconButton, Text, useTheme, View } from 'native-base';
import React from 'react';
import { Image, ScrollView, useWindowDimensions } from 'react-native';
import { useQuery } from 'react-query';
import CustomAppbar from '../../../components/common/custom-appbar.component';
import { StackNavigatorParams } from '../../../components/common/navigator-stack.component';
import { getArticleDetails } from '../../../services/repositories/article';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import LoadingSpinner from '../../../components/common/loading-spinner.component';

type ArticleDetailsScreenProps = {
  navigation: StackNavigationProp<StackNavigatorParams, 'ArticleDetails'>;
  route: RouteProp<StackNavigatorParams, 'ArticleDetails'>;
};

const ArticleDetailsScreen = ({
  navigation,
  route,
}: ArticleDetailsScreenProps) => {
  const { colors } = useTheme();
  const { id } = route.params;
  const contentWidth = useWindowDimensions().width;
  const {
    data: article,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(['articles', id], () => getArticleDetails(id));

  return (
    <React.Fragment>
      <CustomAppbar
        leftAccessory={
          <IconButton
            onPress={() => {
              navigation.goBack();
            }}
            // color={colors.primary}
            icon={
              <MaterialCommunityIcons
                name="arrow-left"
                colors={colors.primary[500]}
                size={24}
              />
            }
          />
        }
        title="Artikel"
      />
      {isLoading ? (
        <LoadingSpinner isFullscreen />
      ) : (
        <ScrollView>
          <Image
            style={{
              width: '100%',
              height: 180,
              overflow: 'hidden',
            }}
            source={{
              uri: article?.thumbnailUrl,
            }}
          />
          <View
            style={{
              backgroundColor: 'rgba(0,0,0,0.75)',
              flex: 1,
            }}
          >
            <View
              style={{
                left: 0,
                bottom: 0,
                padding: 12,
              }}
            >
              <Text
                color="lightText"
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                }}
              >
                {article?.title}
              </Text>
              <Text color="lightText" fontSize="sm" style={{ marginTop: 3 }}>
                oleh: {article?.author}
              </Text>
            </View>
          </View>
          <View padding="4">
            <div dangerouslySetInnerHTML={{ __html: article!.content }} />
          </View>
          {/* <View style={{ padding: 16 }}>
            <RenderHTML
              source={{
                html: article!.content
              }}
              contentWidth={contentWidth}
            />
          </View> */}
        </ScrollView>
      )}
    </React.Fragment>
  );
};

export default ArticleDetailsScreen;
