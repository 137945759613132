import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../utils/api';
import { JWT_TOKEN_KEY } from '../../utils/constants';
import { Order, TransactionCode } from '../../utils/types';

export const getOrders = async () => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  try {
    const response = await api.get<Order[]>(`/orders`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.table(response.data);

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getTransactionCode = async () => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  try {
    const response = await api.get<TransactionCode>(
      `/orders/transaction-code`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log(response);

    return response.data;
  } catch (error: any) {
    console.log(error.response);

    throw new Error(error.message);
  }
};

// export const getOrders = async () => {
//   const { data, error } = await supabase.from<Order>("order").select(
//     `*,
//     store (
//       name,
//       avatar_url
//     ),
//     order_item (
//       quantity,
//       product (
//         name,
//         images
//       )
//     )`
//   );

//   console.log(data);

//   if (error) {
//     console.log(error);
//     throw new Error(error.message);
//   }

//   return data;
// };

export const getOrderById = async (id: number) => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  try {
    const response = await api.get<Order>(`/orders/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
