import { Box, Text, Toast } from 'native-base';
import React, { useCallback } from 'react';

export enum ToastType {
  success,
  error,
  warning,
  info,
}

export type CustomToastProps = {
  type: ToastType;
  content: string;
};

export const useCustomToast = () => {
  const getToastColor = (type: ToastType) => {
    switch (type) {
      case ToastType.success:
        return 'emerald.500';
      case ToastType.error:
        return 'red.500';
      case ToastType.info:
        return 'blue.500';
      case ToastType.warning:
        return 'orange.500';

      default:
        break;
    }
  };

  const showCustomToast = useCallback(({ content, type }: CustomToastProps) => {
    Toast.show({
      placement: 'top',
      render: () => {
        return (
          <Box bg={getToastColor(type)} p="3" rounded="sm" mt="5">
            <Text color="white">{content}</Text>
          </Box>
        );
      },
    });
  }, []);

  const hideCustomToast = useCallback(() => Toast.closeAll(), []);

  return { showCustomToast, hideCustomToast };
};
