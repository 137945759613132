import MaterialCommunityIcons from '@expo/vector-icons/build/MaterialCommunityIcons';
import { StackNavigationProp } from '@react-navigation/stack';
import { IconButton, useTheme } from 'native-base';
// import * as Location from "expo-location";
// import { LocationAccuracy } from "expo-location";
// import { getDistance } from "geolib";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FlatList, Image, Platform, View } from 'react-native';
// import { showLocation } from "react-native-map-link";
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useQuery } from 'react-query';
import CustomAppbar from '../../../components/common/custom-appbar.component';
import GoogleMapView from '../../../components/common/google-map-view.component';
import LoadingSpinner from '../../../components/common/loading-spinner.component';
import { StackNavigatorParams } from '../../../components/common/navigator-stack.component';
import Spacer from '../../../components/common/spacer.component';
import { getStores } from '../../../services/repositories/store';
import { Store } from '../../../utils/types';

type NearestStoresScreenProps = {
  navigation: StackNavigationProp<StackNavigatorParams, 'NearestStores'>;
};

const latitudeDelta = 0.025;
const longitudeDelta = 0.025;

const NearestStoresScreen = ({
  navigation,
}: NearestStoresScreenProps): ReactElement => {
  const { colors } = useTheme();
  const insets = useSafeAreaInsets();
  const [locationLoading, setLocationLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [location, setLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const {
    data: stores,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery('stores', getStores);
  const [selectedStore, setSelectedStore] = useState<Store | undefined>();

  // function orderCistores(cistores: Store[]) {
  //   return cistores?.sort((a, b) => {
  //     return (
  //       getDistance(
  //         location
  //           ? { latitude: location.latitude, longitude: location.longitude }
  //           : { latitude: 0, longitude: 0 },
  //         { latitude: a.latitude, longitude: a.longitude }
  //       ) -
  //       getDistance(
  //         location
  //           ? { latitude: location.latitude, longitude: location.longitude }
  //           : { latitude: 0, longitude: 0 },
  //         { latitude: b.latitude, longitude: b.longitude }
  //       )
  //     );
  //   });
  // }

  return (
    <React.Fragment>
      <CustomAppbar
        leftAccessory={
          <IconButton
            onPress={() => {
              navigation.goBack();
            }}
            // color={colors.primary}
            icon={
              <MaterialCommunityIcons
                name="arrow-left"
                colors={colors.primary[500]}
                size={24}
              />
            }
          />
        }
        title="Cistore Terdekat"
      />
      <View style={{ flex: 1 }}>
        {locationLoading ? (
          <LoadingSpinner isFullscreen />
        ) : (
          <GoogleMapView
            height="100%"
            markers={stores?.map((store) => ({
              lat: store.latitude,
              lng: store.longitude,
            }))}
          />
        )}
      </View>
    </React.Fragment>
  );
};

export default NearestStoresScreen;
