import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../utils/api';
import { JWT_TOKEN_KEY } from '../../utils/constants';
import { Product, ProductCategory } from '../../utils/types';

export const getProductCategories = async () => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  try {
    const response = await api.get<ProductCategory[]>('/product-categories', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getProducts = async ({ queryKey }: any) => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);
  const [_key, { categoryId }] = queryKey;

  try {
    const response = await api.get<Product[]>(`/products`, {
      params: {
        categoryId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getProductById = async (id: number) => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  try {
    const response = await api.get<Product>(`/products/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
