import React, { FunctionComponent } from "react";
import { View } from "react-native";

interface SpacerProps {
  mode?: SpacerMode;
  size?: SpacerSize;
}

type SpacerMode = "vertical" | "horizontal";
type SpacerSize = "xs" | "sm" | "md" | "lg" | "xl";

const Spacer: FunctionComponent<SpacerProps> = ({ mode = "vertical", size = "md" }) => {
  const calcSize = (size: SpacerSize) => {
    switch (size) {
      case "xs":
        return 8;
      case "sm":
        return 12;
      case "md":
        return 16;
      case "lg":
        return 24;
      case "xl":
        return 32;
      default:
        return 0;
    }
  };

  const style = mode === "vertical" ? { height: calcSize(size) } : { width: calcSize(size) };

  return <View style={{ ...style }} />;
};

export default Spacer;
