import { useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  Badge,
  IconButton,
  Image,
  ScrollView,
  Text,
  useTheme,
  View,
} from 'native-base';
import React, { ReactElement, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { useQuery } from 'react-query';
import CustomAppbar from '../../../components/common/custom-appbar.component';
import { StackNavigatorParams } from '../../../components/common/navigator-stack.component';
import Spacer from '../../../components/common/spacer.component';
import {
  getProductCategories,
  getProducts,
} from '../../../services/repositories/product';
import { IDR } from '../../../utils/numbers';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import LoadingSpinner from '../../../components/common/loading-spinner.component';
import { Product } from '../../../utils/types';

type PriceListScreenProps = {
  navigation: StackNavigationProp<StackNavigatorParams, 'PriceList'>;
};

const PriceListScreen = ({
  navigation,
}: PriceListScreenProps): ReactElement => {
  const [categoryId, setCategoryId] = useState<number | undefined>(undefined);
  const { data: productCategories, isLoading: productCategoriesLoading } =
    useQuery('productCategories', getProductCategories);
  const { data: products, isLoading: productsLoading } = useQuery(
    ['products', { categoryId }],
    getProducts
  );

  const { colors } = useTheme();

  return (
    <React.Fragment>
      <CustomAppbar
        leftAccessory={
          <IconButton
            onPress={() => {
              navigation.goBack();
            }}
            // color={colors.primary}
            icon={
              <MaterialCommunityIcons
                name="arrow-left"
                colors={colors.primary[500]}
                size={24}
              />
            }
          />
        }
        title="Daftar Produk"
      />
      <View bg="white" style={{ paddingVertical: 12 }}>
        {productCategoriesLoading ? (
          <LoadingSpinner />
        ) : (
          <ScrollView
            showsHorizontalScrollIndicator={false}
            horizontal
            contentContainerStyle={{ paddingHorizontal: 16 }}
          >
            <TouchableOpacity
              onPress={() => setCategoryId(undefined)}
              activeOpacity={0.75}
            >
              <Badge
                style={{ marginRight: 8, alignItems: 'center' }}
                colorScheme={categoryId === undefined ? 'primary' : 'coolGray'}
                // selected={categoryId === undefined}
              >
                Semua
              </Badge>
            </TouchableOpacity>
            {productCategories?.map((category) => {
              return (
                <TouchableOpacity
                  onPress={() => setCategoryId(category.id)}
                  key={category.id}
                  activeOpacity={0.75}
                >
                  <Badge
                    style={{ marginRight: 8, alignItems: 'center' }}
                    colorScheme={
                      categoryId === category.id ? 'primary' : 'coolGray'
                    }
                  >
                    {category.name}
                  </Badge>
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        )}
      </View>
      <ScrollView
        bg="white"
        contentContainerStyle={{
          paddingBottom: 24,
        }}
      >
        <Spacer />
        {productsLoading ? (
          <LoadingSpinner isFullscreen />
        ) : (
          <View style={{ paddingHorizontal: 16 }}>
            {products?.map((product) => (
              <PriceItem key={product.id} product={product} />
            ))}
          </View>
        )}
      </ScrollView>
    </React.Fragment>
  );
};

interface PriceItemProps {
  product: Product;
}

const PriceItem = ({ product }: PriceItemProps) => {
  const { colors } = useTheme();
  const navigation: any = useNavigation();

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={() => {
        navigation.navigate('TrashDetails', { id: product.id });
      }}
    >
      <View style={{ flexDirection: 'row', marginBottom: 12 }}>
        <Image
          resizeMode="cover"
          style={{ height: 75, width: 75, borderRadius: 8 }}
          source={{ uri: product.images[0] }}
        />
        <Spacer mode="horizontal" />
        <View style={{ flex: 1, alignItems: 'flex-start' }}>
          <Text style={{ fontWeight: '500' }}>{product.name}</Text>
          <Spacer size="xs" />
          <Badge textStyle={{ color: colors.darkText }}>
            {product.unit.name}
          </Badge>
          {/* <Chip
            style={{ backgroundColor: colors.primary }}
            textStyle={{ color: colors.background }}
          >
            {IDR(product.price)}/{product.unit.name}
          </Chip> */}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default PriceListScreen;
