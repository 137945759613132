import { Text, useTheme, View } from 'native-base';
import React, { FunctionComponent } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface CustomAppbarProps {
  leftAccessory?: React.ReactNode;
  rightAccessory?: React.ReactNode;
  title: string | React.ReactNode;
  backgroundColor?: string;
}

const CustomAppbar: FunctionComponent<CustomAppbarProps> = ({
  leftAccessory,
  rightAccessory,
  title,
  backgroundColor,
}) => {
  const { colors } = useTheme();
  const insets = useSafeAreaInsets();

  return (
    <React.Fragment>
      <View
        style={{
          height: insets.top,
        }}
        bg="white"
      />
      <View
        style={{
          // marginTop: insets.top,
          height: 56,
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          paddingHorizontal: 16,
          borderBottomColor: '#f1f1f1',
          borderBottomWidth: 1,
        }}
      >
        {leftAccessory ? leftAccessory : <View />}
        <View
          pointerEvents="none"
          style={{
            zIndex: -1,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.white,
          }}
        >
          {typeof title === 'string' ? (
            <Text
              style={{
                position: 'absolute',
                fontSize: 18,
              }}
            >
              {title}
            </Text>
          ) : (
            title
          )}
        </View>
        {rightAccessory}
      </View>
    </React.Fragment>
  );
};

export default CustomAppbar;
