import { yupResolver } from '@hookform/resolvers/yup';
import { StackNavigationProp } from '@react-navigation/stack';
import { FormControl, Icon, Input, Text, View } from 'native-base';
import React, { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dimensions, Image, TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { StackNavigatorParams } from '../../../components/common/navigator-stack.component';
import Spacer from '../../../components/common/spacer.component';
import { useToggle } from '../../../hooks/useToggle';
import { useAuth } from '../../../services/contexts/authContext';
import {
  SnackbarType,
  useSnackbar,
} from '../../../services/contexts/snackbarContext';
import { login, LoginBody } from '../../../services/repositories/auth';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import CustomButton from '../../../components/common/custom-button.component';
import { ToastType, useCustomToast } from '../../../hooks/useCustomToast';

const schema = yup.object({
  email: yup
    .string()
    .required('Email tidak boleh kosong')
    .email('Masukkan alamat email yang valid'),
  password: yup.string().required('Password tidak boleh kosong'),
});

type LoginScreenProps = {
  navigation: StackNavigationProp<StackNavigatorParams, 'Login'>;
};

const LoginScreen = ({ navigation }: LoginScreenProps): ReactElement => {
  const [isObscure, toggleObscure] = useToggle(true);
  const { setToken } = useAuth();
  const { showCustomToast } = useCustomToast();
  const insets = useSafeAreaInsets();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const loginMutation = useMutation((formData: LoginBody) => login(formData), {
    onSuccess: (result) => {
      showCustomToast({
        content: 'Login berhasil!',
        type: ToastType.success,
      });
      setToken(result.data.accessToken);
    },
    onError: (error: any) => {
      console.log(error.response);
      showCustomToast({
        content:
          error.response?.data?.message || 'Kesalahan saat login. Coba lagi',
        type: ToastType.error,
      });
    },
  });

  const onSubmit = (data: any) => {
    loginMutation.mutate(data);
  };

  return (
    <React.Fragment>
      <View bg="white" flex="1">
        <View
          style={{
            elevation: 8,
            paddingHorizontal: 16,
            paddingTop: insets.top + 16,
          }}
          alignSelf="center"
          width="full"
          maxWidth="lg"
        >
          <Image
            resizeMode="contain"
            style={{ height: 150, width: 240, alignSelf: 'center' }}
            source={require('../../../../assets/images/ciroes.png')}
          />
          {/* 
        <Subheading style={{ textAlign: "center" }}>
          Silakan masuk dengan menggunakan email
        </Subheading> */}
          <Spacer />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormControl w="full" isInvalid={!!errors.email}>
                <FormControl.Label isInvalid={!!errors.email}>
                  Email
                </FormControl.Label>
                <Input
                  isInvalid={!!errors.email}
                  bg="coolGray.100"
                  autoFocus
                  size="lg"
                  placeholder="Alamat Email"
                  keyboardType="email-address"
                  autoCapitalize="none"
                  autoCorrect={false}
                  onChangeText={(v) => onChange(v)}
                  onBlur={onBlur}
                  value={value}
                />
                <FormControl.ErrorMessage>
                  {errors?.email?.message}
                </FormControl.ErrorMessage>
              </FormControl>
            )}
            name="email"
            defaultValue=""
          />
          <Spacer />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormControl w="full" isInvalid={!!errors.password}>
                <FormControl.Label isInvalid={!!errors.password}>
                  Password
                </FormControl.Label>
                <Input
                  isInvalid={!!errors.password}
                  bg="coolGray.100"
                  size="lg"
                  placeholder="Password"
                  secureTextEntry={isObscure}
                  onChangeText={(v) => onChange(v)}
                  onBlur={onBlur}
                  value={value}
                  InputRightElement={
                    <Icon
                      onPress={toggleObscure}
                      as={
                        isObscure ? (
                          <MaterialCommunityIcons name="eye" />
                        ) : (
                          <MaterialCommunityIcons name="eye-off" />
                        )
                      }
                      size={5}
                      mr="2"
                      color="muted.400"
                    />
                  }
                />
                <FormControl.ErrorMessage>
                  {errors?.password?.message}
                </FormControl.ErrorMessage>
              </FormControl>
            )}
            name="password"
            defaultValue=""
          />
          <Spacer size="lg" />
          <CustomButton
            onPress={handleSubmit(onSubmit)}
            mode="contained"
            isLoading={loginMutation.isLoading}
            contentStyle={{ paddingVertical: 6 }}
          >
            Masuk
          </CustomButton>
          {/* <Spacer />
        <Subheading style={{ textAlign: 'center' }}>
          atau masuk menggunakan
        </Subheading>
        <Spacer />
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <IconButton
            icon={({ size, color }) => {
              return (
                <Image
                  source={require('../../../assets/images/google.png')}
                  style={{
                    width: size,
                    height: size,
                  }}
                />
              );
            }}
            size={24}
            color="#EA4335"
            style={{ borderWidth: 1, borderColor: colors.disabled }}
            onPress={() => console.log('Pressed')}
          />
          <IconButton
            icon={({ size, color }) => {
              return (
                <Image
                  source={require('../../../assets/images/facebook.png')}
                  style={{
                    width: size - 12,
                    height: size,
                  }}
                />
              );
            }}
            size={24}
            color="#4267B2"
            style={{ borderWidth: 1, borderColor: colors.disabled }}
            onPress={() => console.log('Pressed')}
          />
          <IconButton
            icon={({ size, color }) => {
              return (
                <Image
                  source={require('../../../assets/images/apple.png')}
                  style={{
                    width: size - 4,
                    height: size,
                  }}
                />
              );
            }}
            size={24}
            color="#A2AAAD"
            style={{ borderWidth: 1, borderColor: colors.disabled }}
            onPress={() => console.log('Pressed')}
          />
        </View> */}
          <Spacer size="lg" />
          <Text style={{ textAlign: 'center' }}>
            Belum punya akun?{' '}
            <TouchableOpacity
              activeOpacity={0.8}
              onPress={() => navigation.replace('Signup')}
            >
              <Text color="primary.500">Daftar</Text>
            </TouchableOpacity>
          </Text>
        </View>
      </View>
    </React.Fragment>
  );
};

export default LoginScreen;
