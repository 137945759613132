import { RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import { IconButton, Text, useTheme, View } from 'native-base';
import React, { ReactElement, useEffect, useState } from 'react';
import QRCode from 'react-native-qrcode-svg';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useQuery, useQueryClient } from 'react-query';
import CustomAppbar from '../../../components/common/custom-appbar.component';
import CustomButton from '../../../components/common/custom-button.component';
import LoadingSpinner from '../../../components/common/loading-spinner.component';
import { StackNavigatorParams } from '../../../components/common/navigator-stack.component';
import Spacer from '../../../components/common/spacer.component';
import { getTransactionCode } from '../../../services/repositories/order';
import MaterialCommunityIcons from '@expo/vector-icons/build/MaterialCommunityIcons';
// import { socket } from "../../../utils/socket";

type QRModalPermanentScreenProps = {
  navigation: StackNavigationProp<StackNavigatorParams, 'QRModalPermanent'>;
};

const QRModalPermanentScreen = ({
  navigation,
}: QRModalPermanentScreenProps): ReactElement => {
  const { colors } = useTheme();
  const queryClient = useQueryClient();
  const insets = useSafeAreaInsets();
  const [id, setId] = useState<string | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const logoFromFile = require('../../../../assets/images/ciro-primary-transparent.png');
  const { isLoading } = useQuery('transaction-code', getTransactionCode, {
    onSuccess: (data) => {
      console.log(data);
      setId(data.id);
    },
  });

  // useEffect(() => {
  //   if (!id) return;

  //   socket.on(`orders/${id}`, (isCompleted: boolean) => {
  //     console.log("isCompleted", isCompleted);
  //     // const newMessages = messages.concat(message);
  //     queryClient.invalidateQueries("profile");
  //     queryClient.invalidateQueries("orders");
  //     navigation.goBack();
  //   });

  //   return () => {
  //     socket.off(`orders/${id}`);
  //   };
  // }, [id]);

  return (
    <React.Fragment>
      <CustomAppbar
        leftAccessory={
          <IconButton
            onPress={() => {
              navigation.goBack();
            }}
            // color={colors.primary}
            icon={
              <MaterialCommunityIcons
                name="arrow-left"
                colors={colors.primary[500]}
                size={24}
              />
            }
          />
        }
        title="QR Code Antar"
      />
      {isLoading || !id ? (
        <LoadingSpinner isFullscreen />
      ) : (
        <View style={{ flex: 1, padding: 16 }}>
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 24,
            }}
          >
            {/* <Image
          resizeMode="contain"
          style={{ height: width * 0.7, width: width * 0.7 }}
          source={require("../../../assets/images/qr.png")}
        /> */}
            <QRCode
              value={JSON.stringify({
                transactionCode: id,
                type: 'CiroQRCode',
              })}
              logo={logoFromFile}
              // logoSize={60}
              // logoMargin={3}
              logoBackgroundColor={colors.white}
              size={200}
              color={colors.primary[500]}
            />
            <Spacer size="xl" />
            <Text style={{ textAlign: 'center' }}>
              Bawa sampahmu dan tunjukkan QR Code kamu kepada Collecting Point,
              tunggu Collecting Point mengonfirmasi jumlah sampahmu. Jika sudah
              berhasil silakan cek kembali saldo kamu. Teruslah jaga lingkungan
              kamu, karena dengen CIROES #SemuaJadiBermanfaat!
            </Text>
          </View>
          <CustomButton
            mode="contained"
            contentStyle={{ paddingVertical: 6 }}
            // labelStyle={{ color: colors.surface }}
            style={{ marginBottom: insets.bottom + 16 }}
            onPress={() => navigation.goBack()}
          >
            Tutup
          </CustomButton>
        </View>
      )}
    </React.Fragment>
  );
};

export default QRModalPermanentScreen;
