import { yupResolver } from '@hookform/resolvers/yup';
import { StackNavigationProp } from '@react-navigation/stack';
import { FormControl, Icon, Input, Text, View } from 'native-base';
import React, { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { StackNavigatorParams } from '../../../components/common/navigator-stack.component';
import Spacer from '../../../components/common/spacer.component';
import { useToggle } from '../../../hooks/useToggle';
import { useAuth } from '../../../services/contexts/authContext';
import {
  SnackbarType,
  useSnackbar,
} from '../../../services/contexts/snackbarContext';
import { signup, SignupBody } from '../../../services/repositories/auth';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import CustomButton from '../../../components/common/custom-button.component';

const schema = yup.object({
  name: yup.string().required('Nama tidak boleh kosong'),
  email: yup
    .string()
    .required('Email tidak boleh kosong')
    .email('Masukkan alamat email yang valid'),
  password: yup
    .string()
    .required('Password tidak boleh kosong')
    .min(8, 'Minimum 8 karakter'),
});

type SignupScreenProps = {
  navigation: StackNavigationProp<StackNavigatorParams, 'Signup'>;
};

const SignupScreen = ({ navigation }: SignupScreenProps): ReactElement => {
  const [isObscure, toggleObscure] = useToggle(true);
  const { setToken } = useAuth();
  const { showSnackbar } = useSnackbar();
  const insets = useSafeAreaInsets();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const signupMutation = useMutation(
    (formData: SignupBody) => signup(formData),
    {
      onSuccess: (result) => {
        showSnackbar('Pendaftaran berhasil!', SnackbarType.SUCCESS);
        setToken(result.data.accessToken);
        // navigation.replace("Navigator");
        // SecureStore.setItemAsync('jwt-token', result.data.token);
        // navigation.goBack();
      },
      onError: (error: any) => {
        showSnackbar(
          error.response?.data?.message ||
            'Kesalahan saat mendaftar. Coba lagi',
          SnackbarType.ERROR
        );
      },
    }
  );

  const onSubmit = (data: any) => {
    signupMutation.mutate(data);
  };

  return (
    <React.Fragment>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={{ flex: 1 }}
      >
        <View bg="white" flex="1">
          <View
            style={{
              elevation: 8,
              paddingHorizontal: 16,
              paddingTop: insets.top + 16,
            }}
            alignSelf="center"
            width="full"
            maxWidth="lg"
          >
            {/* <Image
          resizeMode="contain"
          style={{ height: 150, width: 240, alignSelf: 'center' }}
          source={require('../../../assets/images/ciro.png')}
        /> */}
            <Spacer size="lg" />
            <Text style={{ fontWeight: 'bold', fontSize: 32 }}>
              Selamat Datang
            </Text>
            <Text style={{ fontWeight: 'bold', fontSize: 20 }}>
              Pahlawan lingkungan
            </Text>
            <Spacer size="lg" />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl w="full" isInvalid={!!errors.name}>
                  <FormControl.Label isInvalid={!!errors.name}>
                    Email
                  </FormControl.Label>
                  <Input
                    isInvalid={!!errors.name}
                    bg="coolGray.100"
                    autoFocus
                    size="lg"
                    placeholder="Nama"
                    autoCorrect={false}
                    onChangeText={(v) => onChange(v)}
                    onBlur={onBlur}
                    value={value}
                  />
                  <FormControl.ErrorMessage>
                    {errors?.name?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
              name="name"
              defaultValue=""
            />
            <Spacer />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl w="full" isInvalid={!!errors.email}>
                  <FormControl.Label isInvalid={!!errors.email}>
                    Email
                  </FormControl.Label>
                  <Input
                    isInvalid={!!errors.email}
                    bg="coolGray.100"
                    autoFocus
                    size="lg"
                    placeholder="Alamat Email"
                    keyboardType="email-address"
                    autoCapitalize="none"
                    autoCorrect={false}
                    onChangeText={(v) => onChange(v)}
                    onBlur={onBlur}
                    value={value}
                  />
                  <FormControl.ErrorMessage>
                    {errors?.email?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
              name="email"
              defaultValue=""
            />
            <Spacer />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControl w="full" isInvalid={!!errors.password}>
                  <FormControl.Label isInvalid={!!errors.password}>
                    Password
                  </FormControl.Label>
                  <Input
                    isInvalid={!!errors.password}
                    bg="coolGray.100"
                    size="lg"
                    placeholder="Password"
                    secureTextEntry={isObscure}
                    onChangeText={(v) => onChange(v)}
                    onBlur={onBlur}
                    value={value}
                    InputRightElement={
                      <Icon
                        onPress={toggleObscure}
                        as={
                          isObscure ? (
                            <MaterialCommunityIcons name="eye" />
                          ) : (
                            <MaterialCommunityIcons name="eye-off" />
                          )
                        }
                        size={5}
                        mr="2"
                        color="muted.400"
                      />
                    }
                  />
                  <FormControl.ErrorMessage>
                    {errors?.password?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
              name="password"
              defaultValue=""
            />
            <Spacer size="lg" />
            <CustomButton
              // onPress={() => loginMutation.mutate()}
              onPress={handleSubmit(onSubmit)}
              mode="contained"
              isLoading={signupMutation.isLoading}
              contentStyle={{ padding: 8 }}
            >
              Daftar
            </CustomButton>
            <Spacer size="lg" />
            <Text style={{ textAlign: 'center' }}>
              Sudah punya akun?{' '}
              <TouchableOpacity
                activeOpacity={0.8}
                onPress={() => navigation.replace('Login')}
              >
                <Text color="primary.500">Masuk</Text>
              </TouchableOpacity>
            </Text>
          </View>
        </View>
      </KeyboardAvoidingView>
    </React.Fragment>
  );
};

export default SignupScreen;
