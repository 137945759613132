import { StackNavigationProp } from '@react-navigation/stack';
import { Divider, IconButton, Text, useTheme, View, Image } from 'native-base';
// import * as Linking from "expo-linking";
import React from 'react';
import { Dimensions, ScrollView, TouchableOpacity } from 'react-native';
// import { SliderBox } from 'react-native-image-slider-box';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { useQuery } from 'react-query';
import CustomAppbar from '../../../components/common/custom-appbar.component';
import LoadingSpinner from '../../../components/common/loading-spinner.component';
import { StackNavigatorParams } from '../../../components/common/navigator-stack.component';
import Spacer from '../../../components/common/spacer.component';
import { getArticleCategories } from '../../../services/repositories/article';
import { getBanners } from '../../../services/repositories/banner';
import { getOrders } from '../../../services/repositories/order';
import { getProfile } from '../../../services/repositories/user';
import { IDR } from '../../../utils/numbers';
import ArticleMenuGridOverlayItem from '../components/article-menu-grid-overlay.component';
import MenuGridOverlayItem from '../components/menu-grid-overlay.component';
import CustomButton from '../../../components/common/custom-button.component';

type HomeScreenProps = {
  navigation: StackNavigationProp<StackNavigatorParams, 'Navigator'>;
};

const HomeScreen = ({ navigation }: HomeScreenProps) => {
  const { colors } = useTheme();
  const width = Dimensions.get('window').width;
  const {
    data: profile,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery('profile', getProfile);

  const {
    data: banners,
    isLoading: bannersIsLoading,
    isRefetching: bannersIsRefetching,
    refetch: bannersRefetch,
  } = useQuery('banners', getBanners);
  const {
    data: articleCategories,
    isLoading: articleCategoriesIsLoading,
    isRefetching: articleCategoriesIsRefetching,
    refetch: articleCategoriesRefetch,
  } = useQuery('article-categories', getArticleCategories);
  const { data: orders, isLoading: ordersIsLoading } = useQuery(
    ['orders'],
    getOrders
  );

  const activeOrder = orders?.find((order) => order.status === 'ongoing');

  console.log(activeOrder);

  return (
    <React.Fragment>
      <CustomAppbar
        backgroundColor={colors.white}
        title=""
        rightAccessory={
          <IconButton
            onPress={() => {
              navigation.navigate('QRModalPermanent');
            }}
            // color={colors.primary}
            icon={
              <MaterialCommunityIcons
                name="qrcode"
                colors={colors.primary[500]}
                size={24}
              />
            }
          />
        }
        leftAccessory={
          <Image
            resizeMode="contain"
            style={{ width: 120, height: 40 }}
            source={require('../../../../assets/images/ciroes.png')}
          />
        }
      />
      {isLoading || bannersIsLoading || articleCategoriesIsLoading ? (
        <LoadingSpinner isFullscreen />
      ) : (
        <ScrollView>
          {/* <Image
          resizeMode="cover"
          style={{ width: width, height: width / 3 }}
          source={require("../../../assets/images/dried_maggot.jpeg")}
        /> */}
          {/* <SliderBox
            images={banners ? banners?.map((banner) => banner.imageUrl) : []}
            autoplay
            circleLoop
            imageLoadingColor={colors.primary}
            resizeMode="contain"
            dotColor={colors.secondary[700]}
            sliderBoxHeight={width / (16 / 8)}
          /> */}

          <Spacer size="lg" />
          {ordersIsLoading || !activeOrder ? null : (
            <React.Fragment>
              <View
                style={{
                  marginHorizontal: 16,
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                  borderRadius: 8,
                  elevation: 4,
                  // backgroundColor: colors.primary
                }}
              >
                <View>
                  <View>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          style={{
                            fontWeight: 'bold',
                            color: colors.secondary[700],
                          }}
                        >
                          Pesanan Aktif
                        </Text>
                        <Spacer size="xs" />
                        <Text style={{ color: colors.coolGray[100] }}>
                          {activeOrder?.type === 'delivery'
                            ? 'Antar'
                            : 'Jemput'}
                        </Text>
                        <Text
                          style={{
                            fontWeight: 'bold',
                            color: colors.coolGray[100],
                          }}
                        >
                          {activeOrder?.store?.name}
                        </Text>
                        <Text style={{ color: colors.coolGray[100] }}>
                          {IDR(activeOrder?.totalAmount)}
                        </Text>
                      </View>
                      {/* <View>
                        <IconButton
                          icon="qrcode"
                          color={colors.coolGray[100]}
                          size={30}
                          onPress={() => {
                            navigation.navigate("QRModal", { id: activeOrder!.id });
                          }}
                        />
                        <IconButton
                          icon="whatsapp"
                          color={colors.background}
                          size={30}
                          onPress={async () => {
                            console.log(activeOrder);
                            await Linking.openURL(
                              `https://wa.me/62${activeOrder.store?.user.phone}`
                            );
                          }}
                        />
                      </View> */}
                    </View>
                    <Spacer />
                    <CustomButton
                      onPress={() => {
                        navigation.navigate('Orders');
                      }}
                      mode="text"
                      color={colors.secondary[700]}
                    >
                      Lihat Semua Pesanan
                    </CustomButton>
                  </View>
                </View>
              </View>
              <Spacer />
            </React.Fragment>
          )}
          <View
            bg="white"
            style={{
              marginHorizontal: 16,
              paddingHorizontal: 16,
              paddingVertical: 16,
              borderRadius: 8,
              justifyContent: 'space-between',
              alignItems: 'center',
              elevation: 4,
              flexDirection: 'row',
            }}
          >
            <View>
              <Text fontSize="md">Saldo</Text>
              <Spacer size="lg" mode="horizontal" />
              <Text fontSize="2xl" style={{ fontWeight: 'bold' }}>
                {profile?.balance && IDR(profile?.balance)}
              </Text>
            </View>
          </View>
          <Spacer />
          <Divider />
          <Spacer />
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingHorizontal: 16,
            }}
          >
            <View bg="secondary.700" style={{ borderRadius: 24 }}>
              <MaterialCommunityIcons
                style={{ padding: 6 }}
                color={colors.white}
                size={20}
                name="more"
              />
            </View>
            <Spacer mode="horizontal" size="sm" />
            <Text fontSize="lg">Kategori</Text>
          </View>
          <View
            flex={1}
            flexDirection="row"
            flexWrap="wrap"
            padding="4"
            justifyContent="space-between"
          >
            <MenuGridOverlayItem
              label="Produk"
              backgroundImageUrl="https://res.cloudinary.com/rikoriswandha/image/upload/v1634703898/ciro/jonathan-chng-OTDyDgPoJ_0-unsplash_drjtvh.jpg"
              onPress={() => {
                navigation.navigate('PriceList');
              }}
            />
            <MenuGridOverlayItem
              label="Cistore Terdekat"
              backgroundImageUrl="https://res.cloudinary.com/rikoriswandha/image/upload/v1634703903/ciro/refhad-SyH6CiIjzs8-unsplash_wshwva.jpg"
              onPress={() => {
                navigation.navigate('NearestStores');
              }}
            />
          </View>
          {/* <Spacer size="xl" />
          <Spacer size="lg" /> */}
          <View flexDirection="row" alignItems="center" paddingX="4">
            <View
              style={{
                borderRadius: 24,
                backgroundColor: colors.secondary[700],
              }}
            >
              <MaterialCommunityIcons
                style={{ padding: 6 }}
                color={colors.white}
                size={20}
                name="format-quote-close"
              />
            </View>
            <Spacer mode="horizontal" size="sm" />
            <Text fontSize="lg">Artikel</Text>
          </View>
          <Spacer size="sm" />
          <Text
            style={{
              paddingHorizontal: 16,
            }}
          >
            Tambah pengetahuanmu dengan inspirasi kreatif pengolahan sampah masa
            kini
          </Text>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              flex: 1,
              padding: 16,
              justifyContent: 'space-between',
            }}
          >
            {articleCategories &&
              articleCategories.map((category) => (
                <ArticleMenuGridOverlayItem
                  key={category.id}
                  onPress={() => {
                    navigation.navigate('ArticleCategories', {
                      category: category,
                    });
                  }}
                  label={category.name}
                  backgroundImageUrl={category.imageUrl}
                />
              ))}
            {/* <ArticleMenuGridOverlayItem
            onPress={() => {
              navigation.navigate("ArticleCategories", { category: "Tips CIRO" });
            }}
            label="Tips CIRO"
            backgroundImageUrl="https://res.cloudinary.com/rikoriswandha/image/upload/v1634709652/ciro/johannes-plenio-voQ97kezCx0-unsplash_gtnsm2.jpg"
          />
          <ArticleMenuGridOverlayItem
            onPress={() => {
              navigation.navigate("ArticleCategories", { category: "Fun Fact" });
            }}
            label="Fun Fact"
            backgroundImageUrl="https://res.cloudinary.com/rikoriswandha/image/upload/v1634709650/ciro/marten-newhall-uAFjFsMS3YY-unsplash_lhkldw.jpg"
          />
          <ArticleMenuGridOverlayItem
            onPress={() => {
              navigation.navigate("ArticleCategories", { category: "Sharing" });
            }}
            label="Sharing"
            backgroundImageUrl="https://res.cloudinary.com/rikoriswandha/image/upload/v1634709654/ciro/priscilla-du-preez-XkKCui44iM0-unsplash_w5hx8f.jpg"
          />
          <ArticleMenuGridOverlayItem
            onPress={() => {
              navigation.navigate("ArticleCategories", { category: "Campaign" });
            }}
            label="Campaign"
            backgroundImageUrl="https://res.cloudinary.com/rikoriswandha/image/upload/v1634709647/ciro/melany-rochester-FAQ4RE76Nvs-unsplash_lzqejx.jpg"
          /> */}
          </View>
        </ScrollView>
      )}
      {/* <Surface
        style={{
          position: 'absolute',
          width: Dimensions.get('screen').width - 24,
          height: 80,
          bottom: 20 + insets.bottom,
          left: 12,
          borderRadius: 40,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <View style={{ alignItems: 'center' }}>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => {
              navigation.navigate('SubscriptionOverview');
            }}
            style={{
              padding: 8,
              backgroundColor: colors.primary,
              borderRadius: 24,
            }}
          >
            <MaterialCommunityIcons
              color={colors.background}
              size={30}
              name="check-decagram"
            />
          </TouchableOpacity>
          <Text style={{ fontSize: 12, marginTop: 4 }}>Subscribe</Text>
        </View>
        <View style={{ alignItems: 'center' }}>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => {
              navigation.navigate('PickupItemList');
            }}
            style={{
              padding: 8,
              backgroundColor: colors.primary,
              borderRadius: 24,
            }}
          >
            <MaterialCommunityIcons
              color={colors.background}
              size={30}
              name="truck"
            />
          </TouchableOpacity>
          <Text style={{ fontSize: 12, marginTop: 4 }}>Angkut</Text>
        </View>
        <View style={{ alignItems: 'center' }}>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => {
              navigation.navigate('PriceList');
            }}
            style={{
              padding: 8,
              backgroundColor: colors.primary,
              borderRadius: 24,
            }}
          >
            <MaterialCommunityIcons
              color={colors.background}
              size={30}
              name="currency-usd"
            />
          </TouchableOpacity>
          <Text style={{ fontSize: 12, marginTop: 4 }}>Cek Harga</Text>
        </View>
        <View style={{ alignItems: 'center' }}>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => {
              navigation.navigate('Marketplace');
            }}
            style={{
              padding: 8,
              backgroundColor: colors.primary,
              borderRadius: 24,
            }}
          >
            <MaterialCommunityIcons
              color={colors.background}
              size={30}
              name="shopping"
            />
          </TouchableOpacity>
          <Text style={{ fontSize: 12, marginTop: 4 }}>Marketplace</Text>
        </View>
      </Surface> */}
    </React.Fragment>
  );
};

export default HomeScreen;
