import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import AppLoading from 'expo-app-loading';
import React, { useEffect, useState } from 'react';
import ArticleCategoriesScreen from '../../features/articles/screens/article-categories.screen';
import ArticleDetailsScreen from '../../features/articles/screens/article-details.screen';
import LoginScreen from '../../features/auth/screens/login.screen';
import SignupScreen from '../../features/auth/screens/signup.screen';
import HomeScreen from '../../features/home/screens/home.screen';
import NearestStoresScreen from '../../features/home/screens/nearest-stores.screen';
import QRModalPermanentScreen from '../../features/home/screens/qr-modal-permanent.screen';
import PriceListScreen from '../../features/price/screens/price-list.screen';
import TrashDetailsScreen from '../../features/price/screens/trash-details.screen';
import { useAuth } from '../../services/contexts/authContext';
import { useSnackbar } from '../../services/contexts/snackbarContext';
import { ArticleCategory } from '../../services/repositories/article';
import NavigatorTabbar from './navigator-tabbar.component';

export type StackNavigatorParams = {
  Onboarding: undefined;
  Navigator: undefined;
  Home: undefined;
  Signup: undefined;
  Login: undefined;
  OngoingPickupMap: undefined;
  PickupMap: undefined;
  // DeliveryMap: { store: Store };
  PickupItemList: undefined;
  AddNewPickupItem: undefined;
  PickupConfirmation: undefined;
  PriceList: undefined;
  TrashDetails: { id: number };
  NearestDelivery: undefined;
  QRModal: { id: number };
  QRModalPermanent: undefined;
  Orders: undefined;
  OrderDeliveryProgress: { id: number };
  OrderPickupProgress: { id: number };
  // ProductListModal: { items: OrderItem[] };
  ArticleCategories: { category: ArticleCategory };
  ArticleDetails: { id: number };
  NearestStores: undefined;
  // WebView: { url: string; title: string };
  // ChangePassword: undefined;
  // UpdateProfile: { profile: UserProfile };
  // ImagePicker: undefined;
  // WithdrawBalance: undefined;
  // Dashboard: { user: UserProfile };
};

const Stack = createStackNavigator<StackNavigatorParams>();

const NavigatorStack = () => {
  const { state, hideSnackbar } = useSnackbar();
  const { state: authState } = useAuth();
  const [value, setValue] = useState<boolean | null>(null);
  const { getItem } = useAsyncStorage('firstTimeInstall');

  const readItemFromStorage = async () => {
    const item = await getItem();

    console.log(item);

    if (item) {
      setValue(JSON.parse(item));
    } else {
      setValue(false);
    }
  };

  useEffect(() => {
    readItemFromStorage();
  }, []);

  return value === null ? (
    <AppLoading />
  ) : (
    <React.Fragment>
      <NavigationContainer>
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          {!authState.isLoggedIn ? (
            <Stack.Group>
              {/* {!value && <Stack.Screen name="Onboarding" component={OnboardingScreen} />} */}
              <Stack.Screen name="Login" component={LoginScreen} />
              <Stack.Screen name="Signup" component={SignupScreen} />
            </Stack.Group>
          ) : (
            <Stack.Group>
              <Stack.Screen name="Home" component={HomeScreen} />
              <Stack.Screen
                name="ArticleCategories"
                component={ArticleCategoriesScreen}
              />
              <Stack.Screen
                name="ArticleDetails"
                component={ArticleDetailsScreen}
              />
              <Stack.Screen name="PriceList" component={PriceListScreen} />
              <Stack.Screen
                name="TrashDetails"
                component={TrashDetailsScreen}
              />
              <Stack.Screen
                name="NearestStores"
                component={NearestStoresScreen}
              />
              <Stack.Screen
                name="QRModalPermanent"
                component={QRModalPermanentScreen}
              />
            </Stack.Group>

            // <Stack.Group>
            //   <Stack.Screen name="Navigator" component={NavigatorTabbar} />
            //   <Stack.Screen name="OngoingPickupMap" component={OngoingPickupMapScreen} />
            //   <Stack.Screen name="PickupMap" component={PickupMapScreen} />
            //   <Stack.Screen name="DeliveryMap" component={DeliveryMapScreen} />
            //   <Stack.Screen name="PickupItemList" component={PickupItemListScreen} />
            //   <Stack.Screen name="PickupConfirmation" component={PickupConfirmationScreen} />
            //   <Stack.Screen name="PriceList" component={PriceListScreen} />
            //   <Stack.Screen name="TrashDetails" component={TrashDetailsScreen} />
            //   <Stack.Screen name="NearestDelivery" component={NearestDeliveryScreen} />
            //   <Stack.Screen name="Orders" component={OrdersScreen} />
            //   <Stack.Screen name="OrderDeliveryProgress" component={OrderDeliveryProgressScreen} />
            //   <Stack.Screen name="OrderPickupProgress" component={OrderPickupProgressScreen} />
            //   <Stack.Screen name="ArticleCategories" component={ArticleCategoriesScreen} />
            //   <Stack.Screen name="ArticleDetails" component={ArticleDetailsScreen} />
            //   <Stack.Screen name="NearestStores" component={NearestStoresScreen} />
            //   <Stack.Screen name="WebView" component={WebViewScreen} />
            //   <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} />
            //   <Stack.Screen name="UpdateProfile" component={UpdateProfileScreen} />
            //   <Stack.Screen name="ImagePicker" component={ImagePickerScreen} />
            //   <Stack.Screen name="WithdrawBalance" component={WithdrawBalanceScreen} />
            //   <Stack.Screen name="Dashboard" component={DashboardScreen} />
            // </Stack.Group>
          )}

          {/* <Stack.Screen
          name="SubscriptionOverview"
          component={SubscriptionOverviewScreen}
        /> */}
        </Stack.Navigator>
      </NavigationContainer>
      {/* <Snackbar
        style={{ backgroundColor: state?.bgColor }}
        visible={state?.show}
        onDismiss={hideSnackbar}
        action={{
          label: "Close",
          onPress: () => {
            hideSnackbar();
          }
        }}
        duration={5000}
      >
        {state.message}
      </Snackbar> */}
    </React.Fragment>
  );
};

export default NavigatorStack;
