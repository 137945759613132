import { Button, IButtonProps } from "native-base";
import React from "react";

type CustomButtonProps = IButtonProps;

const CustomButton = (props: CustomButtonProps) => {
  return <Button size="lg" rounded="xl" {...props} />;
};

export default CustomButton;
