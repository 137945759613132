import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../utils/api';
import { JWT_TOKEN_KEY } from '../../utils/constants';

export type ArticleCategory = {
  id: number;
  name: string;
  imageUrl: string;
};

export const getArticleCategories = async () => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  try {
    const response = await api.get<ArticleCategory[]>('/article-categories', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export type Article = {
  id: number;
  title: string;
  content: string;
  author: string;
  thumbnailUrl: string;
  slug?: string;
  createdAt: string;
};

export const getArticles = async ({ queryKey }: any) => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  const [_key, { categoryId }] = queryKey;

  try {
    const response = await api.get<Article[]>('/articles', {
      params: {
        categoryId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getArticleDetails = async (id: number) => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  try {
    const response = await api.get<Article>(`/articles/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
