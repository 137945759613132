import { RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import { Badge, IconButton, Text, useTheme, View } from 'native-base';
import React, { ReactElement } from 'react';
import { ScrollView } from 'react-native';
import { useQuery } from 'react-query';
import { Carousel } from '../../../components/common/carousel-image.component';
import CustomAppbar from '../../../components/common/custom-appbar.component';
import { StackNavigatorParams } from '../../../components/common/navigator-stack.component';
import Spacer from '../../../components/common/spacer.component';
import { getProductById } from '../../../services/repositories/product';
import { IDR } from '../../../utils/numbers';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import LoadingSpinner from '../../../components/common/loading-spinner.component';

type TrashDetailsScreenProps = {
  navigation: StackNavigationProp<StackNavigatorParams, 'TrashDetails'>;
  route: RouteProp<StackNavigatorParams, 'TrashDetails'>;
};

const TrashDetailsScreen = ({
  navigation,
  route,
}: TrashDetailsScreenProps): ReactElement => {
  const id = route.params?.id;
  const { colors } = useTheme();

  const {
    data: product,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(['products', id], () => getProductById(id));

  return (
    <React.Fragment>
      <CustomAppbar
        leftAccessory={
          <IconButton
            onPress={() => {
              navigation.goBack();
            }}
            // color={colors.primary}
            icon={
              <MaterialCommunityIcons
                name="arrow-left"
                colors={colors.primary[500]}
                size={24}
              />
            }
          />
        }
        title="Daftar Produk"
      />
      {isLoading ? (
        <LoadingSpinner isFullscreen />
      ) : (
        <ScrollView>
          <Carousel images={product?.images ?? []} />
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 12,
              alignItems: 'center',
              padding: 16,
            }}
          >
            <View style={{ flex: 1, alignItems: 'flex-start' }}>
              <Text fontSize="2xl">{product?.name}</Text>
              <Spacer size="xs" />
              <Badge
                colorScheme="primary"
                // textStyle={{ color: colors.background }}
              >
                {product?.price && product?.unit?.name}
              </Badge>
              <Spacer />
              <Text fontSize="lg">Deskripsi</Text>
              <Spacer size="xs" />
              <Text>{product?.description}</Text>
              <Spacer />
              <Text fontSize="lg">Contoh</Text>
              <Spacer size="xs" />
              <Text>{product?.example}</Text>
            </View>
          </View>
        </ScrollView>
      )}
    </React.Fragment>
  );
};

export default TrashDetailsScreen;
