import axios from 'axios';

// export const baseURL = "https://e938-36-85-236-230.ngrok.io";
// export const baseURL = "https://238b-114-5-110-47.ngrok.io";
export const baseURL = 'https://ciro-backend.herokuapp.com';

const api = axios.create({
  baseURL: `${baseURL}/api/v1`,
  // baseURL: "https://be41-114-4-217-118.ngrok.io/api/v1",
  timeout: 60000,
  timeoutErrorMessage: 'Request to server timeout',
});

// api.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response.status === 401 || error.response.status === 403) {
//       SecureStore.deleteItemAsync("CiroJWTToken");
//     }
//   }
// );

export { api };
