// import { useAuth } from "@contexts/auth.context";
// import { useAsyncStorage } from "@react-native-async-storage/async-storage";
// import { STORAGE_ROLE_KEY } from "@utils/constants";
// import AppLoading from "expo-app-loading";
import {
  Kanit_100Thin,
  Kanit_200ExtraLight,
  Kanit_300Light,
  Kanit_400Regular,
  Kanit_500Medium,
  Kanit_600SemiBold,
  Kanit_700Bold,
  Kanit_800ExtraBold,
  Kanit_900Black,
  useFonts,
} from '@expo-google-fonts/kanit';
import AppLoading from 'expo-app-loading';
import { extendTheme, NativeBaseProvider } from 'native-base';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { customColors } from '../../infrastructure/theme';

type AppBootstrapProps = {
  children: React.ReactNode;
};

const fontConfig = {
  Inter: {
    100: {
      normal: 'Kanit_100Thin',
    },
    200: {
      normal: 'Kanit_200ExtraLight',
    },
    300: {
      normal: 'Kanit_300Light',
    },
    400: {
      normal: 'Kanit_400Regular',
    },
    500: {
      normal: 'Kanit_500Medium',
    },
    600: {
      normal: 'Kanit_600SemiBold',
    },
    700: {
      normal: 'Kanit_700Bold',
    },
    800: {
      normal: 'Kanit_800ExtraBold',
    },
    900: {
      normal: 'Kanit_900Black',
    },
  },
};

export default function AppBootstrap({
  children,
}: AppBootstrapProps): ReactElement {
  const [fontsLoaded] = useFonts({
    Kanit_100Thin,
    Kanit_200ExtraLight,
    Kanit_300Light,
    Kanit_400Regular,
    Kanit_500Medium,
    Kanit_600SemiBold,
    Kanit_700Bold,
    Kanit_800ExtraBold,
    Kanit_900Black,
  });
  // const { authLoaded } = useAuth();
  const [storageLoaded, setStorageLoaded] = useState(false);

  // const { getItem } = useAsyncStorage(STORAGE_ROLE_KEY);

  const theme = extendTheme({
    colors: {
      // Add new color
      ...customColors,
      // Redefinig only one shade, rest of the color will remain same.
    },
    fontConfig,
    fonts: {
      heading: 'Kanit_600SemiBold',
      body: 'Kanit_400Regular',
      mono: 'Kanit_300Light',
    },
  });
  // const [authLoaded, setAuthLoaded] = useState(false);

  // useEffect(() => {
  //   async function checkCurrentUser() {
  //     try {
  //       const user = await Auth.currentAuthenticatedUser();
  //       setUser(user);
  //     } catch (error) {
  //       setUser(null);
  //     }
  //     setAuthLoaded(true);
  //   }
  //   checkCurrentUser();
  // }, []);

  return fontsLoaded ? (
    <Fragment>
      <NativeBaseProvider theme={theme}>{children}</NativeBaseProvider>
    </Fragment>
  ) : (
    <AppLoading />
  );
}
