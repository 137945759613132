import { Text, useTheme, View } from 'native-base';
import React, { ReactElement, ReactNode } from 'react';
import { ImageBackground, TouchableOpacity } from 'react-native';

type MenuGridItemProps = {
  backgroundImageUrl: string;
  label: string;
  disabled?: boolean;
  onPress?: () => void;
};

const MenuGridOverlayItem = ({
  label,
  backgroundImageUrl,
  disabled,
  onPress,
}: MenuGridItemProps) => {
  const { colors } = useTheme();
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      activeOpacity={0.7}
      style={{ width: '49%', marginBottom: 8 }}
    >
      <ImageBackground
        style={{
          flex: 1,
          height: 120,
          overflow: 'hidden',
          borderRadius: 8,
        }}
        source={{ uri: backgroundImageUrl }}
      >
        <View
          style={{
            backgroundColor: disabled
              ? 'rgba(160,160,160,0.75)'
              : 'rgba(0,0,0,0.65)',
          }}
          flex={1}
        >
          <View
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              padding: 8,
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 20,
              }}
              color="lightText"
            >
              {label}
            </Text>
          </View>
          {disabled ? (
            <View
              style={{
                position: 'absolute',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <Text style={{ fontWeight: '700', fontSize: 20 }}>Segera</Text>
            </View>
          ) : null}
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
};

export default MenuGridOverlayItem;
