import { Center, Spinner, View } from "native-base";
import React from "react";

type LoadingSpinnerProps = {
  isFullscreen?: boolean;
};

const LoadingSpinner = ({ isFullscreen = false }: LoadingSpinnerProps) => {
  return isFullscreen ? (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Center>
        <Spinner size="lg" />
      </Center>
    </View>
  ) : (
    <Center>
      <Spinner size="lg" />
    </Center>
  );
};

export default LoadingSpinner;
