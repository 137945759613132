import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../utils/api';
import { JWT_TOKEN_KEY } from '../../utils/constants';

// export const checkUserStatus = ({ email }: { email: string }) => {
//   return api.post(
//     "/auth/check",
//     { email },
//     {
//       headers: { "Content-Type": "application/json" }
//     }
//   );
// };

export interface LoginBody {
  email: string;
  password: string;
}

export const login = async (formData: LoginBody) => {
  return api.post('/auth/login', formData, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export interface SignupBody {
  name: string;
  password: string;
  email: string;
}

export const signup = async (formData: SignupBody) => {
  return api.post('/auth/signup', formData, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export interface ChangePasswordBody {
  oldPassword: string;
  newPassword: string;
}

export const updatePassword = async (formData: ChangePasswordBody) => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  console.log(formData);

  return api.post('/auth/change-password', formData, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

// export const updateEmail = async (newEmail: string) => {
//   const { data, error } = await supabase.auth.update({
//     email: newEmail
//   });

//   if (error) {
//     console.log(error);
//     throw new Error(error.message);
//   }

//   return data;
// };
