import { Image, View } from 'native-base';
import React, { useCallback, useRef, useState } from 'react';
import { Dimensions } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

type SlideProps = {
  data: string;
};

const Slide = ({ data }: SlideProps) => {
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image
        source={{ uri: data }}
        resizeMode="cover"
        style={{
          height: Dimensions.get('window').width,
          width: Dimensions.get('window').width,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    </View>
  );
};

type PaginationProps = {
  index: number;
  images: string[];
};

const Pagination = ({ index, images }: PaginationProps) => {
  return (
    <View
      bg="secondary.700"
      style={{
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        bottom: 0,
        paddingVertical: 6,
      }}
      pointerEvents="none"
    >
      {images.map((_, i) => {
        return (
          <View
            key={i}
            bg={index === i ? 'primary.500' : 'white'}
            style={[
              { width: 10, height: 10, borderRadius: 5, marginHorizontal: 2 },
            ]}
          />
        );
      })}
    </View>
  );
};

type CarouselProps = {
  images: string[];
};

export const Carousel = ({ images }: CarouselProps) => {
  const [index, setIndex] = useState(0);
  const indexRef = useRef(index);
  indexRef.current = index;
  const onScroll = useCallback((event) => {
    const slideSize = event.nativeEvent.layoutMeasurement.width;
    const index = event.nativeEvent.contentOffset.x / slideSize;
    const roundIndex = Math.round(index);

    const distance = Math.abs(roundIndex - index);

    // Prevent one pixel triggering setIndex in the middle
    // of the transition. With this we have to scroll a bit
    // more to trigger the index change.
    const isNoMansLand = 0.4 < distance;

    if (roundIndex !== indexRef.current && !isNoMansLand) {
      setIndex(roundIndex);
    }
  }, []);

  return (
    <>
      <FlatList
        data={images}
        style={{ flex: 1 }}
        renderItem={({ item }) => {
          return <Slide data={item} />;
        }}
        keyExtractor={(item, index) => `${item}_${index}`}
        pagingEnabled
        horizontal
        showsHorizontalScrollIndicator={false}
        onScroll={onScroll}
      />
      <Pagination index={index} images={images} />
    </>
  );
};
