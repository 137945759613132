import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../utils/api';
import { JWT_TOKEN_KEY } from '../../utils/constants';
import { Store } from '../../utils/types';

export const getStores = async () => {
  const token = await AsyncStorage.getItem(JWT_TOKEN_KEY);

  try {
    const response = await api.get<Store[]>(`/stores`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
