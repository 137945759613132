import { StatusBar } from 'expo-status-bar';
import { Text } from 'native-base';
import { View } from 'react-native';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppBootstrap from './src/components/common/app-bootstrap.component';
import NavigatorStack from './src/components/common/navigator-stack.component';
import { AuthContextProvider } from './src/services/contexts/authContext';
import { SnackbarContextProvider } from './src/services/contexts/snackbarContext';

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <SnackbarContextProvider>
          <AppBootstrap>
            <NavigatorStack />
          </AppBootstrap>
        </SnackbarContextProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}
